import React from 'react'
import {ButtonNoLink} from 'components/buttons'
import {StyledSelect} from 'components/select'
import Card from 'components/cards/'

import Style from './list.module.css'

export class ResourceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: undefined,
      category: undefined,
      tags: [],
      categories: [],
      resourcesFiltered: []
    }
    this.onChangeCategory = this.onChangeCategory.bind(this)
    this.onChangeTag = this.onChangeTag.bind(this)
    this.transformForSelect = this.transformForSelect.bind(this)
    this.filter = this.filter.bind(this)
  }
  componentDidMount() {
    const resources = this.props.resources;
    const categories = new Set();
    const tags = new Set();
    resources.forEach(resource => {
      if(resource.tags) {
        resource.tags.forEach(tag => {
          tags.add(tag.name)
        })
      }
      if(resource.categories) {
        resource.categories.forEach(category => {
          categories.add(category.name)
        })
      }
    });

    this.setState({
      categories: Array.from(categories),
      tags: Array.from(tags),
      resourcesFiltered: this.props.resources
    })
  }
  transformForSelect(array) {
    const newArray = array.map(element => ({value: element, label: element}));
    newArray.unshift({value: '*', label: 'All'})
    return newArray;
  }
  onChangeCategory(selectedCategory) {
    this.setState({category: selectedCategory})
  }
  onChangeTag(selectedTag) {
    this.setState({tag: selectedTag})
  }
  filter() {
    let resourcesFiltered = this.props.resources;
    if(this.state.tag && this.state.tag.value !== '*') {
      resourcesFiltered = resourcesFiltered.filter(element => {
        let included = false;
        if(!element.tags) return false;
        element.tags.forEach(tag => {
          if(tag.name === this.state.tag.value) {

            included = true
          }
        })
        return included;
      });
    }
    if(this.state.category && this.state.category.value !== '*') {
      resourcesFiltered = resourcesFiltered.filter(element => {
        let included = false;
        if(!element.categories) return false;
        element.categories.forEach(category => {
          if(category.name === this.state.category.value) {

            included = true
          }
        })
        return included;
      });
    }

    this.setState({resourcesFiltered})
  }
  render() {
    return (
      <div className={Style.container}>
        <div className={Style.filters}>
          <div className={Style.select}>
            <StyledSelect
              choices={this.transformForSelect(this.state.categories)}
              value={this.state.category}
              onChange={this.onChangeCategory}
            />
          </div>
          <div className={Style.select}>
            <StyledSelect
              choices={this.transformForSelect(this.state.tags)}
              value={this.state.tag}
              onChange={this.onChangeTag}
            />
          </div>
          <ButtonNoLink onClick={this.filter}>SEARCH</ButtonNoLink>
        </div>
        <div>
          {this.state.resourcesFiltered.map(element =>
                                            <div className={Style.resource} key={element.id}>
              <Card 
                type={element.resource_type} 
                image={element.featured_media && element.featured_media.localFile &&element.featured_media.localFile.childImageSharp?element.featured_media.localFile.childImageSharp:null}
                content={element.excerpt}
                {...element} />
              </div>
            )}
        </div>
      </div>
    )
  }
}
