import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'
import Section from 'components/section'
import { withGrid } from '../components/grid/grid';

import {VideoCard} from 'components/cards/mediaCards/videoCard'
import Card from 'components/cards/'
import {ResourceList} from 'components/resources/list'
import { TitleBanner } from 'components/banners/titleBanner';
import {BgPattern} from 'components/backgrounds'
import BgStyle from 'components/backgrounds/patterns.module.css'
import Style from './resources.module.css'

const ResourcesTemplate = ({data, ...props}) => {
  const VideosGrid = withGrid(VideoCard, data.videos.nodes);
  return (
    <Layout
      articleId={`${data.wordpressPage.type}-${data.wordpressPage.wordpress_id}`}
      articleClassName={`${data.wordpressPage.classes}`}
      context = {props.pageContext}
      location = {props.location}
    >
      <TitleBanner title={data.wordpressPage.title} />
      <div className="relative">
        <BgPattern className={`${BgStyle.pattern2} ${Style.bgTop}`}/>
      <Section
        title={data.wordpressPage.acf.latest_videos.title}
        button={data.wordpressPage.acf.latest_videos.button}
      >
        <VideosGrid />
      </Section>
      <Section
        title={data.wordpressPage.acf.ressources_title}
      >
        <ResourceList resources={data.rest.nodes} />
    </Section>
      </div>
    </Layout>
  )
};


export default ResourcesTemplate
export const ResourcesQuery = graphql `
query ResourcesQuery($id: String!){
  wordpressPage(id:{eq:$id}){
    title
    content
    path
    type
    date
    wordpress_id
    classes
    acf {
      latest_videos {
        title
        button {
          title
          url
          target
        }
      }
      ressources_title
    }
  }
  rest:allWordpressWpResources(filter:{resource_type:{ne:"video"}, link:{url:{ne:null}}}) {
    nodes {
      resource_type
      date
      title
      excerpt
      path
      id
      link{
        url
        target
        title
      }
      categories {
        name
      }
      tags {
        name
      }
      isDownloadable
      featured_media {
        localFile {
          ...ContentCardImages
        }
      }
    }
  }
  videos:allWordpressWpResources(filter:{resource_type:{eq:"video"}}, sort: {fields:[menu_order], order:ASC}, limit:3) {
    nodes {
      path
      date(formatString:"LL")
      title
    }
  }
}
`
